import React from 'react'
import Layout from '../components/layout'
import { Link, navigate } from 'gatsby'
import SEO from '../components/SEO'
import { SessionAuthenticator, withSessionContext, SessionContext } from 'clouden-components/context/session'
import { withNotificationContext, NotificationContext } from 'clouden-components/context/notification'
import { withPingContext, PingContext } from '../context/ping'
import { Line } from 'react-chartjs-2'
import { Trans, withTranslation, WithTranslation } from 'react-i18next'
import qs from 'qs'

interface ResultsProps extends WithTranslation {
  location: any
  notificationContext: NotificationContext
  sessionContext: SessionContext
  pingContext: PingContext
}

class AuthenticatedResultsPage extends React.Component<ResultsProps> {
  /*
  state = {
    start: 0,
  }
  */

  componentWillMount() {
    const query = qs.parse(this.props.location.search.slice(1))
    const start = query.start ? parseInt(query.start) : 0
    this.props.pingContext.clearHostResults()
    this.props.pingContext.fetchHost(query.hostId)
    this.props.pingContext.fetchHostResults(query.hostId, 7, start)
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps: any) {
    if (!this.props.location || !prevProps.location) return
    const prevQuery = qs.parse(prevProps.location.search.slice(1))
    const prevStart = prevQuery.start ? parseInt(prevQuery.start) : 0
    const query = qs.parse(this.props.location.search.slice(1))
    const start = query.start ? parseInt(query.start) : 0
    if (query.hostId !== prevQuery.hostId) {
      this.props.pingContext.clearHostResults()
      this.props.pingContext.fetchHost(query.hostId)
      this.props.pingContext.fetchHostResults(query.hostId, 7, start)
    } else if (start !== prevStart) {
      this.props.pingContext.fetchHostResults(query.hostId, 7, start)
    }
  }

  handleRefresh = (event: any) => {
    event.preventDefault()
    const query = qs.parse(this.props.location.search.slice(1))
    const start = query.start ? parseInt(query.start) : 0
    if (event.shiftKey) {
      this.props.pingContext.clearHostCache()
    }
    this.props.pingContext.fetchHost(query.hostId)
    this.props.pingContext.fetchHostResults(query.hostId, 7, start)
  }

  formatDateTime(dt, cutSeconds) {
    if (!dt) return '-'
    const d = new Date(typeof dt === 'string' ? dt + 'Z' : dt)
    return d.toISOString().slice(0, cutSeconds ? 16 : 19).replace('T', ' ')
  }

  setStart(newStart: number) {
    const query = qs.parse(this.props.location.search.slice(1))
    navigate(this.props.location.pathname + '?hostId=' + encodeURIComponent(query.hostId) + '&start=' + newStart)
  /*
    this.setState({
      start: newStart,
    })
    this.context.router.push({
      pathname: this.context.appLocation.pathname,
      query: {
        hostId: this.context.appLocation.query.hostId,
        start: newStart ? newStart : undefined,
      }
    })
    this.props.pingContext.fetchHostResults(this.props.hostId, 7, newStart)
  */
  }

  render() {
    const query = qs.parse(this.props.location.search.slice(1))
    const start = query.start ? parseInt(query.start) : 0
    const labels = []
    const dataset1 = []
    const dataset2 = []
    const results = []
    this.props.pingContext.hostResults.map(result => {
      // Object.keys(resultDay.results).sort().map(timeKey => {
        // const result = resultDay.results[timeKey]
        results.push(result)
        labels.push(result.ca)
        dataset1.push(result.ss === 'OK' ? result.tt : NaN)
        dataset2.push(result.ss !== 'OK' ? result.tt : NaN)
      // })
    })
    const chartData = {
      labels: labels,
      datasets: [{
        label: this.props.t('results_ok', 'OK'),
        fill: true,
        lineTension: 0.3,
        pointRadius: 0,
        pointHitRadius: 10,
        data: dataset1,
      }, {
        label: this.props.t('results_problem', 'Problem'),
        fill: true,
        backgroundColor: '#ee7777',
        borderColor: '#ee0000',
        pointBorderColor: '#ee0000',
        pointBackgroundColor: '#ee5555',
        lineTension: 0.3,
        pointRadius: 4,
        pointHitRadius: 10,
        data: dataset2,
      }],
    }
    const chartOptions = {
      tooltips: {
        callbacks: {
          title: (tooltip, data) => results[tooltip[0].index].ss + ' (' + new Date(results[tooltip[0].index].ca).toISOString().slice(0, 19).replace('T', ' ') + ')',
          label: (tooltip, data) => 'Fetch time: ' + results[tooltip.index].tt + 'ms',
          footer: (tooltip, data) => 'HTTP Status: ' + results[tooltip[0].index].sc,
        },
      },
      scales: {
        xAxes: [{
          type: 'time',
          time: {
            displayFormats: {
              'millisecond': 'MMM DD',
              'second': 'MMM DD',
              'minute': 'MMM DD',
              'hour': 'MMM DD',
              'day': 'MMM DD',
              'week': 'MMM DD',
              'month': 'MMM DD',
              'quarter': 'MMM DD',
              'year': 'MMM DD',
            },
          },
        }],
      },
    }

    return (
      <div className="container">
        {this.props.pingContext.host ? <div>
        <div className="row flex-button-row">
          <div className="col-lg-8">
            <h1 className="mt-5 flex-button-title"><Trans i18nKey="results_title">Ping Results</Trans></h1>
          </div>
          <div className="col-lg-4 flex-button-col">
            <Link to="/dashboard/" className="btn btn-primary"><Trans i18nKey="results_back_to_dashboard">Back</Trans></Link>
            {' '}
            <button className="btn btn-secondary" onClick={this.handleRefresh}><span className={this.props.pingContext.loadingHost || this.props.pingContext.loadingHostResults ? "fa fa-sync-alt fa-spin" : "fa fa-sync-alt"}></span> <Trans i18nKey="results_refresh">Refresh</Trans></button>
            {' '}
            <button className="btn btn-secondary" onClick={this.setStart.bind(this, start + 7)} disabled={this.props.pingContext.loadingHost || this.props.pingContext.loadingHostResults || this.props.pingContext.hostResults.length <= 0}>&laquo;</button>
            <button className="btn btn-secondary" onClick={this.setStart.bind(this, start - 7)} disabled={this.props.pingContext.loadingHost || this.props.pingContext.loadingHostResults || start <= 0}>&raquo;</button>
          </div>
        </div>
      </div> : null}
      {this.props.pingContext.hostResults ?
      <div className="bg-white p-3">
        <Line data={chartData} options={chartOptions} />
      </div> : null}
      {this.props.pingContext.host ?
      <div>
        <br/>
        <div className="row">
          <div className="col-lg-6">
            <div className="host-status">
              <span className={this.props.pingContext.host.status === 'OK' ? "label label-success" : this.props.pingContext.host.status === 'PENDING' ? "label label-warning" : "label label-danger"}>{this.props.pingContext.host.status}</span>
              {' '}
              <a target="_blank" rel="noopener" href={this.props.pingContext.host.url}>{this.props.pingContext.host.url}</a>
            </div>
            {this.props.pingContext.host.error ? <div className="alert alert-danger">
              Host error: {this.props.pingContext.host.error}
            </div> : null}
            <div className="text-muted">
              <div><Trans i18nKey="results_last_checked">Last checked:</Trans> {this.formatDateTime(this.props.pingContext.host.checkedAt, false)} (UTC)</div>
              <div><Trans i18nKey="results_last_result">Last result:</Trans> {this.formatDateTime(this.props.pingContext.host.resultTime, true)} (UTC)</div>
              <div><Trans i18nKey="results_http_status">HTTP status:</Trans> {this.props.pingContext.host.statusCode} {this.props.pingContext.host.expectedStatus && ('' + this.props.pingContext.host.statusCode) !== ('' + this.props.pingContext.host.expectedStatus) ? <span>(expected {this.props.pingContext.host.expectedStatus})</span> : null}</div>
              <div><Trans i18nKey="results_fetch_time">Fetch time:</Trans> {typeof(this.props.pingContext.host.totalTime) === 'number' ? '' + this.props.pingContext.host.totalTime + 'ms' : '-'}</div>
              <div><Trans i18nKey="results_frequency">Frequency:</Trans> {this.props.pingContext.host.frequency}</div>
            </div>
          </div>
          <div className="col-lg-6">
            {this.props.pingContext.loadingHost || this.props.pingContext.loadingHostResults ? <div className="text-right" style={{ color: '#dddddd'}}>Loading...</div> : this.props.pingContext.hostResults && this.props.pingContext.hostResults.length > 0 ? <div className="text-right" style={{ color: '#dddddd' }}><Trans i18nKey="results_showing">Showing results from {{start:this.formatDateTime(this.props.pingContext.hostResults && this.props.pingContext.hostResults.length && this.props.pingContext.hostResults[0] && this.props.pingContext.hostResults[0].rt || null, true)}} to {{end:this.formatDateTime(this.props.pingContext.hostResults && this.props.pingContext.hostResults.length && this.props.pingContext.hostResults[this.props.pingContext.hostResults.length-1] && this.props.pingContext.hostResults[this.props.pingContext.hostResults.length-1].rt || 0, true)}}</Trans></div> : null}
          </div>
        </div>
      </div> : null}
      </div>
    )
  }
}

class ResultsPage extends React.Component<ResultsProps> {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title={"Results"} description="Clouden Ping" />
        <SessionAuthenticator>
          <AuthenticatedResultsPage {...this.props} />
        </SessionAuthenticator>
      </Layout>
    )
  }
}

export default withTranslation()(withNotificationContext(withSessionContext(withPingContext(ResultsPage))))
